const gallery = document.querySelector("[data-gallery]");

if (gallery) {
  var items = document.querySelectorAll("[data-gallery-item]");
  var itemCount = items.length;
  items.forEach(item => {
    // var itemImg = item.querySelector("img")
    // item.dataset["originalWidth"] = itemImg.offsetWidth;
    // item.dataset["originalHeight"] = itemImg.offsetHeight;
    var itemImg = item.querySelector("img")
    item.dataset["originalWidth"] = itemImg.offsetWidth;
    item.dataset["originalHeight"] = itemImg.offsetHeight;
  });

  adjustWidths();

  window.addEventListener("resize", adjustWidths);
}

function adjustWidths() {
  var galleryWidth = gallery.offsetWidth;
  var itemRow = [];
  for (var i = 0; i <= itemCount - 1; i++) {
    itemRow.push(items[i]);
    var rowWidth = getRowWidth(itemRow);
    if (rowWidth >= galleryWidth) {
      resizeRowItems(itemRow, rowWidth, galleryWidth);
      itemRow = [];
    }
  }
}

function getRowWidth(itemRow) {
  var rowWidth = 0;
  // $(row).each(function() {
  //   rowWidth += $(this).data("originalWidth");
  // });
  itemRow.forEach(item => {
    rowWidth += parseInt(item.dataset["originalWidth"]);
  });
  return rowWidth;
}

function resizeRowItems(rowItems, rowWidth, galleryWidth) {
  // console.log("resizeRowItems");
  var marginRight = parseInt(rowItems[0].style.marginRight);
  var itemRowLength = rowItems.length;
  // var margins = marginRight * itemRowLength;
  var margins = 16 * itemRowLength;
  var scale = (galleryWidth - margins) / rowWidth;
  // var scale = galleryWidth / rowWidth;
  for (var i = itemRowLength - 1; i >= 0; i--) {
    var originalWidth = rowItems[i].dataset["originalWidth"];
    var originalHeight = rowItems[i].dataset["originalHeight"];
    let itemImage = rowItems[i].querySelector("img");
    itemImage.style.width = Math.floor(originalWidth * scale) + "px";
    itemImage.style.height = originalHeight * scale + "px";
    // itemImage.style.width = originalWidth + "px";
    // itemImage.style.height = originalHeight + "px";
    // console.log("drin", itemImage);
  }
}
